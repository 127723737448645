import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import ValidationPage from 'components/users/binding/validation';
import { getUserInfo } from 'services/user.service';
import { t } from 'src/i18n/config';

const Validation = (props) => {
  const { location = {} } = props;
  const { state } = location;
  const [user, setUser] = useState({ patients: [] });

  useEffect(() => {
    getUserInfo().then((user) => {
      setUser(user);
    });
  }, []);

  if (state == null) {
    return <div></div>;
  }

  return (
    <Layout>
      <Helmet>
        <title>{t('phoneNumberBinding')}</title>
      </Helmet>
      <ValidationPage phone={state.phone} user={user}></ValidationPage>
    </Layout>
  );
};

Validation.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      phone: PropTypes.string,
    }),
  }),
};

export default Validation;
