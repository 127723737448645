import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Button } from '../../1TalkDesign/Buttons';
import Footer from '../../Footer';
import Loading from '../../1TalkDesign/Loading';
import { TextFieldSecondary } from '../../1TalkDesign/Textfield';
import { validatePasscode } from '../../../services/user.service';
import { phoneValidationSuccess } from '../../../lib/liff';
import { getSearchParamsStrings } from 'src/utils';
import { t } from 'src/i18n/config';

const TextField = styled(TextFieldSecondary)`
  flex: 1;
`;

const Container = styled.div`
  margin: 16px 0;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 24px 16px 0 16px;
  flex-direction: column;
`;

const Description = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 1rem;
  line-height: 1.54;
  letter-spacing: 0.09px;
`;

const ValidationButton = styled(Button)`
  width: calc(100% - 16px);
  margin: 12px 16px;
`;

const CFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Anchor = styled(Link)`
  margin-left: 4px;
  color: ${(props) => props.theme.common.primary};
  text-decoration: unset;
`;

const ResendContainer = styled.div`
  display: flex;
`;

const Validation = ({ phone, user }) => {
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const timeRun = 120;
  const [countdown, setCountdown] = useState(timeRun);
  useEffect(() => {
    let timeRunTemp = timeRun;
    let interval = setInterval(() => {
      timeRunTemp -= 1;
      setCountdown(timeRunTemp);
      if (timeRunTemp === 0) {
        clearInterval(interval);
        setCountdown(0);
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const handleClickConfirmValidation = async () => {
    try {
      setLoading(true);
      await validatePasscode({ phone, passcode });
      const trimmedPhone = phone.replace(/\+886/g, '0');
      phoneValidationSuccess(trimmedPhone);
      if (user.patients.length === 0) {
        const searchParams = getSearchParamsStrings();
        await navigate(`/users/create${searchParams}`, {
          replace: true,
          state: { user: { phone } },
        });
        return;
      }
      await navigate('/users/create/success', { replace: true });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handlePasscodeInput = (event) => {
    setPasscode(event.currentTarget.value);
  };

  return (
    <Container>
      <InputContainer>
        <TextField
          inputProps={{
            inputMode: 'numeric',
          }}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 6);
          }}
          onChange={handlePasscodeInput}
          placeholder={t('enterReceivedSMSCode')}
        />
        <Description>
          {t('verificationCodeSentTo')} {phone} {t('mayTakeAWhile')}
        </Description>
      </InputContainer>
      <CFooter>
        <ValidationButton
          disabled={loading}
          onClick={handleClickConfirmValidation}
        >
          {!loading && t('confirmCode')}
          {loading && <Loading></Loading>}
        </ValidationButton>
        <ResendContainer>
          {t('notReceivedCode')}
          <Anchor to="/users/binding/input">
            {t('retry')} ( {countdown} )
          </Anchor>
        </ResendContainer>
      </CFooter>
    </Container>
  );
};

Validation.propTypes = {
  phone: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default Validation;
