import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      height: '40px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputBase-root': {
      fontSize: '1.125rem',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '0.875rem',
    },
  },
  input: {},
})(TextField);

export const TextFieldDefault = styled(CssTextField)``;

export const TextFieldSecondary = (props) => {
  return <TextFieldDefault variant="outlined" {...props} />;
};
