import styled from 'styled-components';

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: ${(props) => (props.height ? `${props.height}px` : 'unset')};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
  width: 100%;
  display: flex;
`;

export default Footer;
